import React, { useRef, useEffect } from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logos/logo-buderus.svg"
import ogimage from "../../../images/projekte/medl-website/medl-website-hero.jpg"
import Kontakt from "../../../components/kontakt"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Navigation from '../../../components/projekte/navigation'
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from "../../../images/dummyvideo.mp4"
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"

const BuderusPartnerwelt = function (props, ref) {
  const data = useStaticQuery(graphql`
  query PartnerweltQuery {
      heropic: file(relativePath: { eq: "images/projekte/buderus-partnerwelt/thumbleiste/buderus-partnerwelt-03.jpg" }) {
          childImageSharp {
              gatsbyImageData(          
                placeholder: BLURRED,
                layout: FULL_WIDTH,
                aspectRatio: 1.7
              )
            }
      }

      ogimage: file(relativePath: { eq: "images/projekte/buderus-partnerwelt/thumbleiste/buderus-partnerwelt-03.jpg" }) {
          childImageSharp {
              fixed(height: 400) {
                  src
                  height
                  width
              }
          }
      }

      allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/buderus-partnerwelt/thumbleiste"}} sort: {fields: name}) {
          edges {
            node {
              id
              childImageSharp {
                  picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                  pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                }
            }
          }
        }                                     
  }
`)

  const specsData = [
    { title: "Eckdaten", objects: [logo, "Kundenbindungsprogramm"] },
    { title: "Leistungen", objects: ["konzept", "workshop", "design", "redaktion", "produktion", "fotoshooting"] },
]

  return (
    <Layout>
      <SEO
        title="Buderus Partnerwelt"
        description="Q:marketing realisiert Kundenbindungsprogramm für Bosch Thermotechnik GmbH."
        image={data?.ogimage?.childImageSharp?.fixed}
      />

      <BigHeaderVideo mode="mediaFirst" className={`${projekteStyles.child}`} bgVideo="J_NxMzUZ0gY" posterImage="">
        <div className="row py-5 py-md-0">
          <div className="col-12 col-md-5">
            <img src={logo} className="h-40px" />
            <h1>Buderus Partnerwelt</h1>
            <p className="introtext">Kundenprogramm zur Bindung, Unterstützung und Entwicklung aller Fachkunden von Buderus Deutschland auf der Basis differenzierter Leistungen je nach Kundenlevel.</p>
            <AnchorLink
              to="/projekte/b2b-platforms/buderus-partnerwelt/#thumbleiste"
              title="↓ Mehr Erfahren"
              className="q-btn q-btn-primary"
            />
          </div>
          <div className="col-7"></div>
        </div>
      </BigHeaderVideo>

      {/* Bilderstrecke Thumbs */}
      <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6" />

      {/* Reasons why */}
      <ListenModule headline="Reasons why">
        <Liste
          items={[
            "Differenziertes Programm zur Bindung und Unterstützung der Heizungsfachbetriebe unter koordinierter Einbindung aller Fachabteilungen von Buderus Deutschland.",
            "Das Programm bietet je nach Kundenlevel differenzierte Leistungen u. a. aus den Bereichen Marketingunterstützung/Verkaufsförderung, Seminare/Qualifizierung, Produktinformationen und Services.",
            "Für die höchsten beiden Kundenlevel existiert seit langem der Buderus STARCLUB mit exklusiven Leistungen für die Geschäftsführer/Inhaber der besten Fachkunden von Buderus Deutschland.",
            "Das Gesamtkonzept der Partnerwelt (inkl. STARCLUB) basiert auf einem durchdachten Anreiz- und Unterstützungssystem zur Umsatzsicherung und Umsatzsteigerung.",
            "Etabliertes System zur professionellen Unterstützung des Vertriebs.",
            "Kontinuierliche Kundenkommunikation sowie interne Kommunikation (Digital und Print)",
            "Einheitlicher Marktauftritt durch Einhaltung des Corporate Designs"
          ]}
        />
      </ListenModule>

      <Specs data={specsData} />

      {/* navigation */}            
      <Navigation location={props.location} />

      {/* kontakt */}
      <Kontakt ansprechpartner="christian-frieling" />
    </Layout>
  )
}

export default BuderusPartnerwelt
